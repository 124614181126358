
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import "../styles/index.scss";
import "@shopify/polaris/build/esm/styles.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import Layout from "../components/blocks/Layout";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import Link from "next/link";
import Cookie from "../components/common/components/Cookie";
import CookieProvider from "../components/providers/CookieProvider";
import GtmProvider from "../components/providers/GtmProvider";
import { useRouter } from "next/router";
import { getPathnameWithLocale } from "../helpers/getPathnameWithLocale";
import Head from "next/head";
import React from "react";
import { SITE_URL } from "../utils/constants";
import StuffProvider from "../components/providers/StuffProvider";
import TestimonialsProvider from "../components/providers/TestimonialsProvider";
import Suggestion from "../components/common/components/Suggestion";

function LinkWrapper(props) {
  const { children, url, external, ...rest } = props;

  if (external) {
    return (
      <a target="_blank" href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link href={url}>
      <a {...rest}>{children}</a>
    </Link>
  );
}

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const pathName = router.pathname === "/" ? "" : router.pathname;
  return (
    <>
      <Head>
        <meta name="google-adsense-account" content="ca-pub-3358320747558522" />
        <link rel="canonical" href={SITE_URL + getPathnameWithLocale(router)} />
        <link rel="alternate" href={SITE_URL + pathName} hrefLang="x-default" />
        <link rel="alternate" href={SITE_URL + pathName} hrefLang="en" />
        <link
          rel="alternate"
          href={SITE_URL + "/uk" + pathName}
          hrefLang="uk"
        />
      </Head>
      <AppProvider i18n={enTranslations} linkComponent={LinkWrapper}>
        <TestimonialsProvider>
          <StuffProvider>
            <CookieProvider>
              <GtmProvider>
                <Cookie />
                <Suggestion />
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </GtmProvider>
            </CookieProvider>
          </StuffProvider>
        </TestimonialsProvider>
      </AppProvider>
    </>
  );
};

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  