const {
  COMMON_NAMESPACE,
  ACCEPT_COOKIES_NAMESPACE,
  HOME_NAMESPACE,
  RF_NAMESPACE,
  SEL_NAMESPACE,
  RC_NAMESPACE,
  TRAINING_NAMESPACE,
  ABOUT_NAMESPACE,
  HIRE_NAMESPACE,
  PRIVACY_NAMESPACE,
  TERM_OF_USE_NAMESPACE,
  COOKIE_POLICY_NAMESPACE,
  APPS_NAMESPACE,
  SUGGESTION_NAMESPACE,
  CUSTOMER_SUPPORT_NAMESPACE,
  CONTACT_US_NAMESPACE,
  DISCORD_TELEGRAM_NAMESPACE,
  AFFILIATE_TERMS_NAMESPACE,
  DISCORD_EMAIL_NAMESPACE,
} = require("./utils/i18nNameSpaces");

const deepMerge = (target, source) => {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      // If the key is an object, merge deeply
      if (
        typeof source[key] === "object" &&
        source[key] !== null &&
        !Array.isArray(source[key])
      ) {
        if (!target[key]) {
          target[key] = {};
        }
        deepMerge(target[key], source[key]);
      } else {
        // Only copy if the key is missing in the target
        if (!target.hasOwnProperty(key)) {
          target[key] = source[key];
        }
      }
    }
  }
  return target;
};

module.exports = {
  locales: ["en", "uk"],
  defaultLocale: "en",
  localeDetection: false,
  loadLocaleFrom: async (lang, ns) => {
    try {
      const langData = require(`./locales/${lang}/${ns}.json`);
      const defaultLangData = require(`./locales/en/${ns}.json`);

      // Merge missing keys from the default language data
      return deepMerge(langData, defaultLangData);
    } catch (error) {
      console.warn(
        `Namespace "${ns}" not found for language "${lang}", default language is used.`
      );
      return require(`./locales/en/${ns}.json`);
    }
  },
  pages: {
    "*": [
      "footer",
      "header",
      COMMON_NAMESPACE,
      ACCEPT_COOKIES_NAMESPACE,
      SUGGESTION_NAMESPACE,
    ],
    "/": [HOME_NAMESPACE],
    "/react-flow": [RF_NAMESPACE],
    "/selectors": [SEL_NAMESPACE],
    "/resell": [RC_NAMESPACE],
    "/training-center": [TRAINING_NAMESPACE],
    "/about-us": [ABOUT_NAMESPACE],
    "/hire": [HIRE_NAMESPACE],
    "/privacy": [PRIVACY_NAMESPACE],
    "/terms-of-use": [TERM_OF_USE_NAMESPACE],
    "/terms-of-use/discord": [TERM_OF_USE_NAMESPACE],
    "/terms-of-use/shopify": [TERM_OF_USE_NAMESPACE],
    "/cookie-policy": [COOKIE_POLICY_NAMESPACE],
    "/contact-us": [CONTACT_US_NAMESPACE, TRAINING_NAMESPACE],
    "/apps": [APPS_NAMESPACE],
    "/customer-support": [CUSTOMER_SUPPORT_NAMESPACE, TRAINING_NAMESPACE],
    "/discord-telegram": [DISCORD_TELEGRAM_NAMESPACE],
    "/privacy/shopify": [PRIVACY_NAMESPACE],
    "/privacy/discord": [PRIVACY_NAMESPACE],
    "/affiliate-terms": [AFFILIATE_TERMS_NAMESPACE],
    "/discord-email": [DISCORD_EMAIL_NAMESPACE],
  },
};
